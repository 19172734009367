@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: $white;
	color: $black;

	padding: 6rem;

	@include sm-down {
		padding: 4rem 2rem;
	}
}

.header {
	text-align: center;

	h2 {
		max-width: 30ch;
		margin-inline: auto;
	}

	p {
		max-width: 70ch;
		margin-inline: auto;
		color: $text-grey-dark;
	}
}

.plus {
	margin-top: 2.5rem;
	svg {
		margin-left: 0.5rem !important;
	}
}

.carousel_inner {
	display: grid;
	grid-template-areas: 'center';

	> * {
		grid-area: center;
	}
}

.carousel_outer {
	max-width: 50rem;
	margin-inline: auto;
	border-radius: 1rem;
	overflow: hidden;
	margin-bottom: $spacing-lg;

	@include sm-down {
		margin-bottom: $spacing-md;
	}

	video {
		width: 100%;
		display: block;
	}
}

.buttons {
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;

	margin: 2.5rem auto;
}

.slide_button {
	background: $black;
	opacity: 0.5;
	padding: 1px 1.5rem;
	border-radius: $br-pill;

	transition: opacity 0.25s ease-out;
}

.slide_button_active {
	opacity: 1;
}

.hero_image {
	img {
		max-width: 50rem;
		margin-inline: auto;
		border-radius: 1rem;

		opacity: 0;

		transition: opacity 0.25s ease-out;
	}
}

.hero_image_active {
	img {
		opacity: 1;
	}
}

.modal {
	opacity: 0;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);

	backdrop-filter: blur(8px);
	color: $white;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 2rem;

	overflow: auto;
	overflow-x: hidden;
	height: 100vh;

	visibility: hidden;

	transition: opacity, visibility, 0.25s ease-out;

	z-index: 9998;

	@include sm-down {
		padding: 1rem;
	}

	.modal_inner {
		scale: 0.9;
	}
}

.modal_open {
	opacity: 1;
	visibility: visible;

	.modal_inner {
		scale: 1;
	}
}

.modal_inner_wrapper {
	margin-inline: auto;

	border-radius: 0.5rem;
	position: relative;

	background: $black;
}

.modal_inner {
	display: grid;
	justify-content: center;
	grid-template-areas: 'hero hero' 'textA textA' 'globe textB' 'textC textC';
	padding: 0 8rem 8rem;

	transition: all 0.25s ease-out;
	row-gap: 7rem;

	@include sm-down {
		grid-template-areas: 'hero' 'textA' 'globe' 'textB' 'textC';

		padding: 0rem 2rem 4rem;
		row-gap: 4rem;
	}
}

.closeButton {
	position: sticky;
	top: 0;
	left: 100%;
	margin: 2rem 2rem 3.25rem;
	border-radius: 0.5rem;
	padding: 0.5rem;
	@include sm-down {
		margin: 2rem 2rem 0;
	}
	background: rgba(255, 255, 255, 0.25);

	* {
		line-height: 0;
	}
	z-index: 9999;
}

.modal_header {
	text-align: center;
	grid-area: hero;

	h2 {
		@include headline3;
		margin: 4rem auto 0;
	}

	.energy_image {
		img {
			max-width: 50rem;
			margin-inline: auto;
			border-radius: 1rem;
		}
	}

	@include sm-down {
		h2 {
			@include headline5;
			margin: 3rem 0 0;
		}
	}
}

.textA {
	grid-area: textA;

	@include headline5;
	max-width: 655px;

	@include sm-down {
		@include body1;
	}
}

.disclaimer {
	display: block;
	padding-top: 1rem;
	@include body5;

	opacity: 0.66;
}

.globe {
	grid-area: globe;
	margin-left: 0;
	margin-right: 8rem;

	filter: drop-shadow(0 0 80px rgba(0, 217, 88, 0.1));

	@include sm-down {
		margin: 0;
	}
}

.textBContainer {
	grid-area: textB;

	p {
		@include headline5;
		max-width: 40ch;

		color: $white;

		margin-bottom: 5rem;
	}

	em {
		font-style: unset;
	}

	small {
		@include headline7;
		font-weight: 400; // TODO bad

		color: #a6a6a6;
	}

	.tag {
		@include tag1;
		padding: 0.25rem 0.75rem;
		color: #a6a6a6;

		border-radius: 0.5rem;
		border: 1px solid #a6a6a6;

		text-transform: uppercase;

		margin-top: 0.5rem;
	}

	@include sm-down {
		p {
			@include body1;

			margin-bottom: 2rem;
		}

		small {
			@include body6;
		}
	}
}

.textC {
	grid-area: textC;
	color: $white;

	@include headline5;
	max-width: 24ch;

	@include sm-down {
		@include body1;
	}
}
